<template>
  <div class="green-border-box in-padd white-bg" style="min-height:500px;">
    <div style="padding:2%;">
      <div style="float:right;">

      </div>
      <h2 style="text-align: center;">Campaigns Calendar <select v-model="brandId" @change="filterEventsByBrand" style="float:right;margin-right:0px;">
        <option value>All Brands</option>
        <option v-for="brand in brands" :value="brand.id" :key="brand.id">{{brand.name}}</option>
      </select></h2>
      <FullCalendar :options='calendarOptions' />
    </div>
  </div>
</template>

<script >
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data () {
    return {
      brands: [],
      brandId: '',
      brandLimitPerPage: 1000,
      brandOffset: 0,
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        select: this.handleDateSelect,
        eventsSet: this.handleEvents,
        events: []
      }
    }
  },
  mounted () {
    this.getCampaings()
    this.getBrands()
    // this.getNonGeoCampaigns()
  },
  methods: {
    getCampaings () {
      this.$store.dispatch('getCampaignCalendarData', this.brandId).then(response => {
        this.calendarOptions.events = response.body
      })
    },
    getBrands (clear) {
      this.$store.dispatch('getBrandsList', { limit: this.brandLimitPerPage, offset: this.brandOffset, sort: 'name' }).then((response) => {
        this.brands.push(...response.body.data)
        if (this.brands.length < response.body.count) {
          this.brandOffset += this.brandLimitPerPage
          this.getBrands()
        }
      })
    },
    filterEventsByBrand () {
      this.calendarOptions.events = []
      this.getCampaings()
    },
    handleEvents (e) {
      // console.log(e)
    },
    handleDateClick (e) {
      // console.log(e)
    },
    handleDateSelect (e) {
      // console.log(e)
    },
    handleEventClick (e) {
      console.log(e.event.extendedProps)
      if (e.event.extendedProps.type === 'GEO') {
        this.$router.push('/sadmin/campaigns/' + e.event.id + '/pickls')
      } else {
        this.$router.push('/sadmin/non-geo-pickl/' + e.event.extendedProps.picklId)
      }
    }
  }
}
</script>
