var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "green-border-box in-padd white-bg",
      staticStyle: { "min-height": "500px" }
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "2%" } },
        [
          _c("div", { staticStyle: { float: "right" } }),
          _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Campaigns Calendar "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.brandId,
                    expression: "brandId"
                  }
                ],
                staticStyle: { float: "right", "margin-right": "0px" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.brandId = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.filterEventsByBrand
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [_vm._v("All Brands")]),
                _vm._l(_vm.brands, function(brand) {
                  return _c(
                    "option",
                    { key: brand.id, domProps: { value: brand.id } },
                    [_vm._v(_vm._s(brand.name))]
                  )
                })
              ],
              2
            )
          ]),
          _c("FullCalendar", { attrs: { options: _vm.calendarOptions } })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }